@import url('https://fonts.googleapis.com/css?family=Montserrat:900,700,500,300|Work+Sans:300');



.container {
  z-index: 300;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  border-right: 10px solid;
  border-image: linear-gradient(var(--angle), #0b0500, #5b1ee1, #001333) 1;
  animation: 25s rotate linear infinite;
}

.container .navigation {
  position: relative;
  width: 80px;
  height: 100%;
  background: #0B0500;
  overflow: hidden;
  transition: 0.5s;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}


.container .navigation.active {
  width: 300px;
}

.container .navigation ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.container .navigation ul li {
  position: relative;
  list-style: none;
  width: 100%;
}

.container .navigation ul li:hover {
  background: #6843d1;
}

.container .navigation ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: #ffff;
  font-weight: 500;
  max-height: 40px;

}


.container .navigation ul li a .icon {
  min-width: 80px;
  height: 40px;
}

.container .navigation ul li a .title {
  position: relative;
  display: block;
  height: 60px;
  line-height: 60px;
  white-space: nowrap;
}

.toggle {
  z-index: 1000000;
  position: absolute;
  right: -30px;
  top: 50%;
  width: 40px;
  height: 40px;
  background-color: black;
  cursor: pointer;
  border: 5px solid #5b1ee1;
  border-radius: 50%;
}

.toggle::before {
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  color: #ffff;

}

.browser-mockup {
  border-top: 2em solid rgba(230, 230, 230, 0.7);
  box-shadow: 0 0.1em 1em 0 rgba(0, 0, 0, 0.4);
  position: relative;
  border-radius: 3px 3px 0 0
}

.browser-mockup:before {
  display: block;
  position: absolute;
  content: '';
  top: -1.25em;
  left: 1em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #f44;
  box-shadow: 0 0 0 2px #f44, 1.5em 0 0 2px #9b3, 3em 0 0 2px #fb5;
}

.browser-mockup.with-tab:after {
  display: block;
  position: absolute;
  content: '';
  top: -2em;
  left: 5.5em;
  width: 20%;
  height: 0em;
  border-bottom: 2em solid white;
  border-left: 0.8em solid transparent;
  border-right: 0.8em solid transparent;
}

.browser-mockup.with-url:after {
  display: block;
  position: absolute;
  content: '';
  top: -1.6em;
  left: 5.5em;
  width: calc(100% - 6em);
  height: 1.2em;
  border-radius: 2px;
  background-color: white;
}

.browser-mockup>* {
  display: block;
}

/* Custom code for the demo */

.browser-mockup {
  margin: 1em 2em;
  flex: 1;

  @media only screen and (max-width: 960px) {
    margin: 10px 0px;
  }
}

img {
  width: 100%;
}

:root {
  --gradient: linear-gradient(90deg, #0b0500, #5b1ee1, #001333);

}

.background {
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  font-size: 1.125em;
  line-height: 1.6;
  color: #333;
  background: #ddd;
  background-size: 300%;
  background-image: var(--gradient);
  animation: bg-animation 25s infinite;

}

.backgroundDrawer {
  background-image: var(--gradient);
  animation: bg-animation 25s infinite;
  position: fixed;
  top: 0;
  right: 0;
}

h1,
h2,
h3,
h5,
h6 {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 900;
}


@keyframes bg-animation {
  0% {
    background-position: left
  }

  50% {
    background-position: right
  }

  100% {
    background-position: left
  }
}