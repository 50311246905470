.mobileMenu{
  background: black;
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 0.97;
  left: 0;
  top: 0;
  z-index: 999;
  -webkit-box-shadow: 6px 0 15px rgba(0,0,0,0.03);
  -moz-box-shadow: 6px 0 15px rgba(0,0,0,0.03);
  box-shadow: 6px 0 15px rgba(0,0,0,0.03);
  overflow-x: auto;

  transition: all 0.7s ease 0s;
  &.hidden{
    top: -100%
  }
  .sLogo{
    display: block;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #dddddd;
    text-align: center;
  }
  >ul{
    padding: 7% 0 0 25%;
    li{
      display: block;
      padding: 0;
      a{
          text-transform: uppercase;
          font-size: 1.3rem;
          color: #6843d1;
          font-weight: 300;
          display: block;
          padding: 13px 30px;
          cursor: pointer;
          font-weight: 900;
          text-decoration: none;
        &:hover{
          color: #6843d1;
        }
      }
      .mItem{
        display: block;
        position: relative;
        z-index: 1;
        &:hover i{
          color: #6843d1;
        }
        
        span{
          text-transform: uppercase;
          font-size: 1.3rem;
          color: #6843d1;
          font-weight: 300;
          display: block;
          padding: 13px 30px;
          cursor: pointer;
          font-weight: 900;
          text-decoration: none;
          &:hover{
            color: #6843d1;
          }
        }
        >div{
          position: absolute;
          // right: 10px;
          top: 50%;
          color: #6843d1;
          transform: translateY(-50%);
          font-size: 22px;
        }
      }
      .subMenu{
        background: black;
        margin-left: 20px;
        a{
          display: block;
          color: #6843d1;
          font-size: 14px; 
        }
      }
    }
  }
}
