@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.bigContainer {
  text-align: center;
  height: 100vh;
}

.smallContainer {
  padding: 2.5rem 0;
}

.App {
  width: 50%;
  margin: 10rem auto;
  height: 570px;
}

.slide img {
  width: 20rem;
  margin: 0 auto;
}

.slide {
  transform: scale(0.2);
  transition: transform 300ms;
  opacity: 0.5;
}

.activeSlide {
  transform: scale(1.1);
  opacity: 1;
}

.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}