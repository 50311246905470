body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: #0B0500;

}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4F4BF5;
  border-radius: 3px;
}
