@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.slideTest {
    transform: scale(0.7);
    transition: transform 300ms;
    opacity: 0.5;
  }
  
  .slideWrapper {
    display: flex;
    justify-content: center;
  }
  
  .prevArrow {
    color: white;
    position: absolute;
    top: 40%;
    left: 60px;
    z-index: 100;
    cursor: pointer;
    font-size: 2rem;    
    z-index: 200;

  }
  
  .nextArrow {
    color: white;
    position: absolute;
    top: 40%;
    right: 60px;
    z-index: 100;
    cursor: pointer;
    font-size: 2rem;
    z-index: 200;
  }